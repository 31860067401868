var wipwrp = window.wipwrp || {};

wipwrp.container = (function($) {

	var self = {};

	wipwrp.pageType = '';


	self.init = function(){
		$('html').removeClass('no-js').addClass('document-ready');
		wipwrp.commons.init();
		_switchPage();
	}


	function _switchPage(){
		// choose you way to find pageType
		wipwrp.pageType = $('body').data('page');
		
		switch(wipwrp.pageType) {
			case 'home':
				// wipwrp.test.init();
			break;
			case 'sacerdote':
			case 'laico':
				wipwrp.subscription.init();
			break;
		}
	};

	return self;

})(jQuery);